<template>
  <div class="container">
    <div class="title">
      <span>在线制版运行日志{{logger.index?`(编号${logger.index})`:''}}<i @click="getLog" class="el-icon-edit-outline sub-icon"></i></span>
      <el-radio-group v-model="logger.type" @input="logUpdateText">
        <template v-for="(label,value,index) in logger.typeArray">
          <el-radio :label="value">{{label}}</el-radio>
        </template>
      </el-radio-group>
    </div>
    <div class="body">
      <textarea name="" v-model="logger.text" disabled id="logarea" cols="30" rows="10"></textarea>
    </div>
  </div>
</template>

<script>
const getLogUrl = (id)=>`/log/hltasklog/${id}`
export default {
  name: "Menu_HlLogViewer",
  data() {
    return {
      logger: {
        text: '',
        type: '0',
        typeArray: {
          '0': '所有',
          '1': '发送方',
          '2': '接收方',
          '9': '系统',
        },
        dataArray: {
          '1': [],
          '2': [],
          '9': [],
        },
        index: null,
      }
    }
  },
  methods:{
    getLog(){
      this.$prompt('请输入编号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\d+$/,
        inputErrorMessage: '编号格式不正确'
      }).then(({ value }) => {
        this.$axios.get(getLogUrl(value)).then(res=>{
          this.logger.dataArray = JSON.parse(res.data.content)
          this.logger.index = res.data.index
          this.logUpdateText()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    logUpdateText(){
      let type = this.logger.type
      let array = []
      if(type === '0'){
        array = this.logger.dataArray[1].concat(this.logger.dataArray[2]).concat(this.logger.dataArray[9])
        array = array.sort((a,b)=>(a[0]>b[0]?1:((a[0]<b[0])?-1:0)))
      }else{
        array = this.logger.dataArray[type]
      }
      this.logger.text = array.map(a=>`${this.logger.typeArray[a[2]]}[${new Date(a[0]).toLocaleTimeString()}]:${a[1]}`).join('\n')
      // console.log('noautoset')
      // let textarea = document.getElementById('logarea');
      // textarea.scrollTop = textarea.scrollHeight;
    },
  },
  created(){
    this.$axios.get(getLogUrl('default')).then(res=>{
      this.logger.dataArray = JSON.parse(res.data.content)
      this.logger.index = res.data.index
      this.logUpdateText()
    })
  },
}
</script>

<style scoped>
.title{
  font-size: 2em;
  line-height: 2.0;
  text-align: center;
  background: #303133;
  color: white;
  height: 15%;
}
.title i{
  font-size: 1.1em;
  margin-left: 5px;
}
.title i:hover{
  color: #5cb6ff;
}
.container{
  height: 100vh;
}
.body{
  height: 85%;
}
.body textarea{
  width: 100%;
  height: 100%;
  background: black;
  color: white;
}
</style>